import React from 'react';
import ReactDOM from 'react-dom';
import App from './views/App.js';


const rootNode = document.getElementById('root')
ReactDOM.render(

    <App />, 
  rootNode
)