// main libraries
import React, { Suspense } from "react";
import ReactGA from "react-ga";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Backend Libraries

import { Spinner } from "reactstrap";

// styles
import "assets/css/nucleo-icons.css";
import "assets/css/blk-design-system-pro-react.css";

// presentation pages
const Index = React.lazy(() => import("views/Index.js"));
const Presentation = React.lazy(() => import("./Presentation.js"));
const Sections = React.lazy(() => import("views/Sections.js"));

//  Lazy Loading Public
const AboutMe = React.lazy(() => import("views/examples/AboutMe.js"));
const BlogPost = React.lazy(() => import("views/examples/BlogPost.js"));
const BlogPosts = React.lazy(() => import("views/examples/BlogPosts.js"));
const ContactMe = React.lazy(() => import("views/examples/ContactMe.js"));
const Pricing = React.lazy(() => import("views/examples/Pricing.js"));
const ProductPage = React.lazy(() => import("views/examples/ProductPage.js"));
const Error404 = React.lazy(() => import("views/examples/Error404.js"));
const Error500 = React.lazy(() => import("views/examples/Error500.js"));
const Products = React.lazy(() => import("views/Products.js"));

//  Lazy Loading Protected

const Account = React.lazy(() => import("./Protected/Account/Account.js"));
const CheckoutPage = React.lazy(() =>
  import("views/Protected/CheckoutPage.js")
);
const InvoicePage = React.lazy(() => import("views/Protected/InvoicePage.js"));
const ChatPage = React.lazy(() => import("views/Protected/ChatPage.js"));
const Ecommerce = React.lazy(() => import("views/Protected/Ecommerce.js"));
const ProfilePage = React.lazy(() => import("views/Protected/ProfilePage.js"));
const BusinessSnapshot = React.lazy(() =>
  import("./GettingToKnowYou/GTKYintro.js")
);
const GettingToKnowYou = React.lazy(() =>
  import("./GettingToKnowYou/GettingToKnowYou.js")
);
const Landing = React.lazy(() => import("./Landing.js"));




ReactGA.initialize("G-7FW7ZZY0ZR", {
  debug: true,
  titleCase: false,
  gaOptions: {
    userId: "kriscodes2",
  },
});

ReactGA.pageview(window.location.pathname + window.location.search);

function App() {

  return (
      <Suspense
        fallback={
          <div
            className="mx-auto my-auto center"
            style={{ height: "100vh", width: "100vh" }} >
            <Spinner animation="grow" variant="secondary" className="mx-auto my-auto" />
          </div>
        }
      >
      
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<Presentation />} />
            <Route
              path="/two-minute-business-plan"
              element={<BusinessSnapshot />}
            />
            <Route path="/getting-to-know-you" element={<GettingToKnowYou />} />
            <Route path="/Presentation" element={<Presentation />} />
            <Route path="/Landing" element={<Landing />} />
            <Route path="/faq" element={<Index />} />
            <Route path="/sections" element={<Sections />} />
            <Route path="/about-me" element={<AboutMe />} />
            <Route path="/blog-post" element={<BlogPost />} />
            <Route path="/blog-posts" element={<BlogPosts />} />
            <Route path="/contact-me" element={<ContactMe />} />
            <Route path="/product-page" element={<ProductPage />} />
            <Route path="/contact-me" element={<Products />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/404-error" element={<Error404 />} />
            <Route path="/500-error" element={<Error500 />} />
            <Route path="/privacy" element={<Error404 />} />
            <Route path="/delete-my-data" element={<Error404 />} />
            <Route path="/terms-of-service" element={<Error404 />} />
            <Route path="/cookies" element={<Error404 />} />
            <Route
              path="/chat-page"
              element={<ChatPage />}
            />
            <Route
              path="/ecommerce"
              element={<Ecommerce />}
              />
            <Route
              path="/account-settings"
              element={<Account />}
            />
            <Route
              path="/profile-page"
              element={<ProfilePage />}
            />
            <Route
              path="/invoice-page"
              element={<InvoicePage />}
            />
            <Route
              path="/checkout-page"
              element={<CheckoutPage />}
            />
          </Routes>
        </BrowserRouter>
        
      </Suspense>
  );
}

export default App;
